

















































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '@/mixins/input-setups'
import AddAdmin from '@/components/AddAdmin/AddAdmin.vue'
import { AddAdminType } from '@/includes/types/AddAdmin/Enums'
import ReceivedAdministrators from "@/components/UsersRights/ReceivedAdministrators/ReceivedAdministrators.vue";
import SiteAdmins from "@/components/UsersRights/SiteAdmins/SiteAdmins.vue";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, Mixins } from 'vue-property-decorator'

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  components: {
    SiteAdmins,
    ReceivedAdministrators,
    AddAdmin,
    ConfigField,
    CenteredColumnLayout,
    PageTitle,
    SwitchInput
  },
  data: () => ({
    AddAdminType
  }),
})
export default class UsersRights extends Mixins(InputSetups) {
  get gods_admins() {
    return this.$store.state.chatState.chat!.common_config.gods_admins
  }

  set gods_admins(value: boolean) {
    this.$store.dispatch('saveChatConfig', { config: { gods_admins: value } })
  }

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
