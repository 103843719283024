import Api from "@/includes/logic/Api";
import { DemoteAdminReq, GetAdminPermsReq, GetAdminPermsRes, PromoteAdminReq } from "@/includes/types/PromoteAdmins";

import { AxiosPromise } from "axios";

export class PromoteAdminsApi {
  static async demoteAdmin(type: 'tg', { chat_id, user_id }: DemoteAdminReq): AxiosPromise<any> {
    return await Api.sendRequest(type, 'demoteadmin', { chat_id, user_id })
  }

  static async promoteAdmin(type: 'tg', body: PromoteAdminReq): AxiosPromise<any> {
    return await Api.sendRequest(type, 'promoteadmin', body)
  }

  static async getAdminPerms(type: 'tg', body: GetAdminPermsReq): AxiosPromise<GetAdminPermsRes> {
    return await Api.sendRequest(type, 'getadminperms', body)
  }
}
