


























































































import TelegramProfileButton from "@/components/TelegramProfileButton.vue";
import AddAdminModal from "@/components/AddAdmin/components/AddAdminModal.vue";
import AddAdminsMixin from "@/components/AddAdmin/AddAdminsMixin";

import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import MiniHelpMessage from "piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue";

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  methods: {
    atSignedLogin
  },
  components: {
    ConfigField,
    MiniHelpMessage,
    AddAdminModal,
    TelegramProfileButton
  }
})
export default class SiteAdmins extends Mixins(AddAdminsMixin) {
  get hasAdmins() {
    return this.$store.state.chatState.chat && !!Object.keys(this.$store.state.chatState.chat.adminsMap).length
  }

  removeAdmin(login: string, id: string) {
    this.$confirm({
      title: this.$t("remove_user_confirm_title").toString(),
      content: this.$t("remove_user_confirm_text", [ login ]).toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.onRemoveClick(id)
      }
    })
  }
}
