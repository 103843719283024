






































































































import TelegramProfileButton from "@/components/TelegramProfileButton.vue";
import { errorNotification, successNotification } from '@/includes/NotificationService';
import { AdminPermissionsEnum } from "@/includes/types/PromoteAdmins";
import { PromoteAdminsApi } from "@/includes/Api/PromoteAdmins.api";
import ReceivedAdminsPermissions from "@/components/UsersRights/ReceivedAdministrators/ReceivedAdminsPermissions.vue";

import ExtendedHelpMessage from "piramis-base-components/src/components/ExtendedHelpMessage.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Component } from 'vue-property-decorator';
import Vue from "vue";

@Component({
  data() {
    return {
      AdminPermissionsEnum
    }
  },
  components: {
    ConfigField,
    ExtendedHelpMessage,
    ReceivedAdminsPermissions,
    TelegramProfileButton,
  }
})
export default class ReceivedAdministrators extends Vue {
  isAddAdminModalOpen = false

  activeUserId: number | null = null

  adminToDemoteId: string | null = null

  get receivedAdministrators() {
    return this.$store.state.chatState.chat!.received_administrators_map
  }

  demoteAdminWarn(userId: string) {
    this.$confirm({
      title: this.$t('delete_admin_confirm_title').toString(),
      content: this.$t('delete_admin_confirm_content').toString(),
      okText: this.$t('accept').toString(),
      onOk: () => {
        this.$baseTemplate.loader.open()

        PromoteAdminsApi.demoteAdmin('tg', {
          chat_id: this.$store.getters.chatId,
          user_id: +userId,
        })
          .then(({ data }) => {
            successNotification()

            this.$store.dispatch('requestChatConfig', { id: this.$store.getters.chatId })

            this.isAddAdminModalOpen = false
          })
          .catch(error => {
            this.adminToDemoteId = userId

            errorNotification(error)
          })
          .finally(() => {
            this.$baseTemplate.loader.close()
          })
      }
    })
  }
}
