import { BaseChatInfo } from "@/includes/types/Chat/types";

export type DemoteAdminReq = {
  chat_id: BaseChatInfo['chat_id']
  user_id: number
}

export type PromoteAdminReq = {
  chat_id: BaseChatInfo['chat_id']
  user_id: number
  anon: boolean
  title: string
  options: Array<AdminPermissionsEnum>
}

export type GetAdminPermsReq = DemoteAdminReq

export type GetAdminPermsRes = {
  options: Array<AdminPermissionsEnum>
  anon: boolean
  title: string
}

export enum AdminPermissionsEnum {
  CanChangeInfo = 'CanChangeInfo',
  CanDeleteMessages = 'CanDeleteMessages',
  CanInviteUsers = 'CanInviteUsers',
  CanRestrictMembers = 'CanRestrictMembers',
  CanPinMessages = 'CanPinMessages',
  CanPromoteMembers = 'CanPromoteMembers',
  CanManageChat = 'CanManageChat',
  CanManageVideoChats = 'CanManageVideoChats',
  CanManageTopics = 'CanManageTopics',
  CanPostStories = 'CanPostStories',
  CanEditStories = 'CanEditStories',
  CanDeleteStories = 'CanDeleteStories',
}
