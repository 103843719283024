var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title'),(_vm.$store.getters.isChatSet)?[_c('site-admins',{attrs:{"add-type":_vm.AddAdminType.Site,"users-path":{
        model: _vm.stateChatPath,
        key: 'adminsMap'
      }}}),_c('received-administrators',{staticClass:"mt-5"}),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_users_rights_settings_title')}},[_c('a-alert',{attrs:{"show-icon":""}},[_c('span',{attrs:{"slot":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('field_users_rights_settings_alert'))},slot:"message"})]),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'gods_admins',
            'hasAnchor': true
          }
        }}}),_c('config-field',{staticClass:"my-3 py-1",attrs:{"has-anchor":"","title":_vm.$t('field_chat_admins_title'),"mini-help-message":_vm.$t('field_chat_admins_mini_help_message'),"help-message":_vm.$t('field_chat_admins_help_message'),"field-key":"chatAdmins"}},[_c('add-admin',{attrs:{"add-type":_vm.AddAdminType.Chat,"users-path":{
            model: _vm.stateChatPath,
            key: 'chatAdminsMap'
          }}})],1),_c('a-divider'),_c('config-field',{staticClass:"my-3 py-1",attrs:{"has-anchor":"","title":_vm.$t('field_users_ignored_by_filters_title'),"mini-help-message":_vm.$t('field_users_ignored_by_filters_mini_help_message'),"field-key":"usersIgnoredByFilters"}},[_c('add-admin',{attrs:{"add-type":_vm.AddAdminType.FiltersIgnored,"users-path":{
            model: _vm.stateChatPath,
            key: 'usersIgnoredByFiltersMap'
          }}})],1)],1)]:_c('a-spin',{staticClass:"w-full text-center"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }